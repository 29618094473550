<template>
  <div class="row">
    <div class="col-xl-12">
      <button class="btn btn-secondary mb-3 w-100" @click="createContentGroup()">
        <i class="uil-plus"></i> İçerik Grubu Ekle
      </button>
    </div>
    <template v-if="formattedTrainingSection">
      <draggable :list="formattedSection" group="formattedSection" class="col-xl-12">
        <div class="mb-2" v-for="(content, index) in formattedSection">
          <ul class="c-curriculum" uk-accordion>
            <li class="uk-open">
              <div class="d-flex justify-content-between align-items-center">
                <span @click="updateGroupTitle(content.id, index)" class="uk-accordion-title" style="cursor: pointer">
                  <i class="uil-folder"> </i>{{ content.name }}
                </span>

                <div>
                  <a class="btn btn-info btn-sm mr-2" style="color: #fff" @click="selectPickerTrigger"
                    :uk-toggle="'target: #modal-add' + content.id">
                    <i class="uil-plus"> </i>
                  </a>
                  <a class="btn btn-sm btn-primary mr-2" @click="updateGroupTitle(content.id, index)">
                    <i class="uil-edit" style="color: white"></i>
                  </a>
                  <a class="btn btn-sm btn-danger" @click="deleteGroupById(content.id)">
                    <i class="uil-trash"></i>
                  </a>
                </div>
              </div>
              <div class="uk-accordion-content">
                <ul class="sec-list" v-if="content.educations">
                  <draggable :list="content.educations" group="content_educations">
                    <li v-for="(education, educationIndex) in content.educations">
                      <div class="sec-list-item">
                        <div>
                          <i class="uil-list-ul uk-sortable-handle"></i>
                          <a :uk-toggle="'target: #modal-edit' + education.id" @click="
                            editEducation(
                              education.id,
                              education.training_file_type_id
                            )
                            " style="cursor: pointer">{{ education.name }}
                          </a>
                          <label class="mb-0 mx-2 contentName">
                            <template v-if="education.training_file_type_id">{{
                              getContentTypeById(
                                education.training_file_type_id
                              ).name
                            }}</template>
                          </label>
                        </div>
                        <div>
                          <div class="btn-act">
                            <a @click="
                              editEducation(
                                education.id,
                                education.training_file_type_id
                              )
                              " :uk-toggle="'target: #modal-edit' + education.id" uk-tooltip="title:Düzenle">
                              <i class="icon-feather-edit"></i>
                            </a>
                            <a @click="deleteEducation(index, educationIndex)" uk-tooltip="title:Kaldır"><i
                                class="icon-feather-trash"></i></a>
                          </div>
                        </div>
                      </div>
                      <div :id="'modal-edit' + education.id" class="uk-modal editContentModal" uk-modal="">
                        <div class="uk-modal-dialog bggray w-75 no-padding">
                          <button class="uk-modal-close-default" type="button" uk-close></button>
                          <div class="uk-modal-header">
                            <h3 class="uk-modal-title">
                              {{ education.name }} Düzenle
                            </h3>
                          </div>
                          <div class="uk-padding-large position-relative uk-grid" uk-grid="">
                            <div class="uk-width-5-5@m">
                              <div class="uk-card-default rounded p-4">
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="form-group row mb-2 input-checkbox">
                                      <div class="col-md-12">
                                        <label class="col-form-label" for="contentTypes">İçerik Türü</label>
                                        <select disabled="disabled" id="contentTypes" v-model="education.training_file_type_id
                                          " class="
                                            selectpickerFileCategory
                                            bg-solid
                                          ">
                                          <option value="">
                                            İçerik Türünü Seçin
                                          </option>
                                          <option v-for="item in formattedContentTypes" :key="item.id"
                                            :data-icon="item.icon" :value="item.id">
                                            {{ item.name }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="form-group">
                                      <label for="contentName">İçerik Adı</label>
                                      <input type="text" id="contentName" class="form-control" v-model="education.name"
                                        placeholder="İçerik adı giriniz." />
                                    </div>
                                    <div class="form-group">
                                      <label for="score">İçerik Skoru</label>
                                      <input type="number" id="score" class="form-control" v-model="education.score"
                                        placeholder="İçerik skoru giriniz." />
                                    </div>
                                    <div class="form-group">
                                      <label for="duration">Süre</label>
                                      <input type="number" id="duration" class="form-control"
                                        v-model="education.duration" placeholder="İçerik süresini giriniz." />
                                    </div>
                                    <div class="
                                        form-group
                                        d-flex
                                        align-items-center
                                      ">
                                      <label class="mr-3">Demo:</label>
                                      <toggle-button :value="education.is_demo == true
                                        ? true
                                        : false
                                        " class="disabled" color="#ae71ff" />
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <h4>
                                      <span v-if="education.training_file_type_id">
                                        {{
                                          getContentTypeById(
                                            education.training_file_type_id
                                          ).name
                                        }}
                                      </span>
                                      Yükle
                                    </h4>
                                    <template v-if="imageArea">
                                      <div class="row">
                                        <div class="col-12">
                                          <template v-if="education.training_file_type_id ==
                                            2
                                          ">
                                            <div v-if="preview" id="preview" v-html="preview"></div>
                                            <img v-else :src="education.content_link" alt="" />
                                          </template>
                                          <template v-if="education.training_file_type_id ==
                                            3
                                          ">
                                            <a class="btn btn-primary" :href="education.content_link"
                                              target="_blank">Pdf'i görüntülemek için
                                              tıklayınız.</a>
                                          </template>
                                          <template v-if="education.training_file_type_id ==
                                            4
                                          ">
                                            <a class="btn btn-primary" :href="education.content_link"
                                              target="_blank">Word
                                              belgesini görüntülemek için
                                              tıklayınız.</a>
                                          </template>
                                          <template v-if="education.training_file_type_id ==
                                            5
                                          ">
                                            <a class="btn btn-primary" :href="education.content_link"
                                              target="_blank">Sunum (pptx) belgesini
                                              görüntülemek için tıklayınız.</a>
                                          </template>
                                        </div>
                                        <div class="col-12">
                                          <div class="uploadOuter test">
                                            <label class="btn btn-info"><i class="uil-upload"></i>{{
                                              $t("general.upload")
                                              }}</label>
                                            <strong>{{
                                              $t("general.or")
                                            }}</strong>
                                            <span class="dragBox">
                                              <i class="icon-feather-file-plus"></i>
                                              {{
                                                $t("general.doc_file_drag_drop")
                                              }}
                                              <template v-if="education.training_file_type_id ==
                                                2
                                              ">
                                                <input accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                                                  type="file" @change="
                                                    dragNdropModal(
                                                      $event,
                                                      education.training_file_type_id
                                                    )
                                                    " />
                                              </template>
                                              <template v-if="education.training_file_type_id ==
                                                3
                                              ">
                                                <input accept="application/pdf" type="file" @change="
                                                  dragNdropModal(
                                                    $event,
                                                    education.training_file_type_id
                                                  )
                                                  " />
                                              </template>
                                              <template v-if="education.training_file_type_id ==
                                                4
                                              ">
                                                <input accept="application/msword" type="file" @change="
                                                  dragNdropModal(
                                                    $event,
                                                    education.training_file_type_id
                                                  )
                                                  " />
                                              </template>
                                              <template v-if="education.training_file_type_id ==
                                                5
                                              ">
                                                <input
                                                  accept="application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                                  type="file" @change="
                                                    dragNdropModal(
                                                      $event,
                                                      education.training_file_type_id
                                                    )
                                                    " />
                                              </template>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </template>

                                    <template v-if="urlArea">
                                      <div class="form-group">
                                        <label for="url">Bağlantı Adresi</label>
                                        <input type="text" v-model="education.training_content" id="url"
                                          class="form-control" />
                                      </div>
                                    </template>

                                    <template v-if="videoArea">
                                      <div class="row">
                                        <div class="col-12">
                                          <div v-if="videoPreview" id="preview" v-html="videoPreview"></div>
                                          <video v-else width="320" height="240" controls>
                                            <source :src="education.content_link" type="video/mp4" />
                                          </video>
                                        </div>
                                        <div class="col-12">
                                          <div class="uploadOuter">
                                            <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i>{{
                                              $t("general.upload") }}</label>
                                            <strong>{{
                                              $t("general.or")
                                            }}</strong>
                                            <span class="dragBox">
                                              <i class="icon-feather-file-plus"></i>
                                              {{
                                                $t(
                                                  "general.video_file_drag_drop"
                                                )
                                              }}
                                              <input accept="video/mp4,video/x-m4v,video/*" type="file"
                                                @change="dragNdropVideo($event)" id="uploadFile" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </template>

                                    <template v-if="audioArea">
                                      <div class="row">
                                        <div class="col-12">
                                          <div v-if="audioPreview" id="preview" v-html="audioPreview"></div>
                                          <audio v-else controls>
                                            <source :src="education.content_link" type="audio/ogg" />
                                          </audio>
                                        </div>
                                        <div class="col-12">
                                          <div class="uploadOuter">
                                            <label for="uploadFileAudio" class="btn btn-info"><i
                                                class="uil-upload"></i>{{
                                                  $t("general.upload") }}</label>
                                            <strong>{{
                                              $t("general.or")
                                            }}</strong>
                                            <span class="dragBox">
                                              <i class="icon-feather-file-plus"></i>
                                              {{
                                                $t(
                                                  "general.audio_file_drag_drop"
                                                )
                                              }}
                                              <input accept="audio/*" type="file" @change="dragNdropAudio($event)"
                                                id="uploadFileAudio" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                              <div class="uk-card-header">
                                <div class="float-right">
                                  <button @click="closeModal" class="
                                      uk-button uk-button-outline-default
                                      mr-2
                                      small
                                      closeButton
                                    " type="button">
                                    <i class="icon-arrow-left"></i> Geri Dön
                                  </button>
                                  <button @click="
                                    editData(content.id, educationIndex)
                                    " class="
                                      uk-button uk-button-primary
                                      small
                                      closeButton
                                    " type="button">
                                    <i class="icon-feather-save"></i> {{ $t("general.save") }}

                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </draggable>
                </ul>
              </div>
            </li>
          </ul>
          <div :id="'modal-add' + content.id" class="uk-modal addContentModal" uk-modal="">
            <div class="uk-modal-dialog bggray w-75 no-padding">
              <button class="uk-modal-close-default" type="button" uk-close></button>
              <div class="uk-modal-header">
                <h3 class="uk-modal-title">İçerik Ekle</h3>
              </div>
              <div class="uk-padding-large position-relative uk-grid" uk-grid="">
                <div class="uk-width-5-5@m">
                  <div class="uk-card-default rounded p-4">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group row mb-2 input-checkbox">
                          <div class="col-md-12">
                            <label class="col-form-label" for="contentTypes">İçerik Türü</label>
                            <select id="contentTypes" v-model="content_type_id" class="selectpickerFileCategory">
                              <option value="">İçerik Türünü Seçin</option>
                              <option v-for="item in formattedContentTypes" :key="item.id" :data-icon="item.icon"
                                :value="item.id">
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <hr />
                        <div class="form-group">
                          <label for="contentName">İçerik Adı</label>
                          <input type="text" id="contentName" class="form-control" v-model="data.name"
                            placeholder="İçerik adı giriniz." />
                        </div>
                        <div class="form-group">
                          <label for="score">İçerik Skoru</label>
                          <input type="number" id="score" class="form-control" v-model="data.score"
                            placeholder="İçerik skoru giriniz." />
                        </div>
                        <div class="form-group">
                          <label for="duration">Süre</label>
                          <input type="number" id="duration" class="form-control" v-model="data.duration"
                            placeholder="İçerik süresini giriniz." />
                        </div>
                        <div class="form-group d-flex align-items-center">
                          <label class="mr-3">Demo:</label>
                          <toggle-button v-model="is_demo" color="#ae71ff" />
                        </div>
                      </div>
                      <div class="col-md-8">
                        <h4>
                          <span v-if="content_type_id">
                            {{ getContentTypeById(content_type_id).name }}
                          </span>
                          Yükle
                        </h4>

                        <template v-if="content_type_id == 2 ||
                          content_type_id == 3 ||
                          content_type_id == 4 ||
                          content_type_id == 5
                        ">
                          <div class="row">
                            <div class="col-12">
                              <div class="uploadOuter">
                                <label class="btn btn-info" for="createNewFileUpload"><i class="uil-upload"></i>{{
                                  $t("general.upload") }}</label>
                                <strong>{{ $t("general.or") }}</strong>
                                <span class="dragBox">
                                  <i class="icon-feather-file-plus"></i>
                                  {{ $t("general.doc_file_drag_drop") }}
                                  <template v-if="content_type_id == 2">
                                    <input id="createNewFileUpload"
                                      accept="image/png, image/gif, image/jpeg, image/jpg, image/webp" type="file"
                                      @change="
                                        dragNdropModal($event, content_type_id)
                                        " />
                                  </template>
                                  <template v-if="content_type_id == 3">
                                    <input id="createNewFileUpload" accept="application/pdf" type="file" @change="
                                      dragNdropModal($event, content_type_id)
                                      " />
                                  </template>
                                  <template v-if="content_type_id == 4">
                                    <input id="createNewFileUpload" accept="application/msword" type="file" @change="
                                      dragNdropModal($event, content_type_id)
                                      " />
                                  </template>
                                  <template v-if="content_type_id == 5">
                                    <input id="createNewFileUpload"
                                      accept="application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                      type="file" @change="
                                        dragNdropModal($event, content_type_id)
                                        " />
                                  </template>
                                </span>
                              </div>
                            </div>
                            <div class="col-12">
                              <div id="preview" v-if="preview" class="filePreview" v-html="preview"></div>
                            </div>
                          </div>
                        </template>
                        <template v-if="content_type_id == 1">
                          <div class="uploadOuter">
                            <label class="btn btn-info" for="UploadscormArea"><i class="uil-upload"></i>{{
                              $t("general.upload") }}</label>
                            <strong>{{ $t("general.or") }}</strong>
                            <span class="dragBox">
                              <i class="icon-feather-file-plus"></i>
                              {{ $t("general.doc_file_drag_drop") }}
                              <template>
                                <input id="UploadscormArea1" :accept="getContentTypeById(content_type_id).accept
                                  " type="file" @change="
                                    dragNdropModal($event, content_type_id)
                                    " />
                              </template>
                            </span>
                          </div>
                          <div id="preview" v-if="preview" class="filePreview" v-html="preview"></div>
                        </template>

                        <template v-if="content_type_id == 8 ||
                          content_type_id == 9 ||
                          content_type_id == 10 ||
                          content_type_id == 11
                        ">
                          <div class="form-group">
                            <label for="url">Bağlantı Adresi</label>
                            <input type="text" v-model="data.url" id="url" class="form-control" />
                          </div>
                        </template>

                        <template v-if="content_type_id == 7">
                          <div class="row">
                            <div class="col-12">
                              <div class="uploadOuter">
                                <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i>{{
                                  $t("general.upload") }}</label>
                                <strong>{{ $t("general.or") }}</strong>
                                <span class="dragBox">
                                  <i class="icon-feather-file-plus"></i>
                                  {{ $t("general.video_file_drag_drop") }}
                                  <input accept="video/*" type="file" @change="dragNdropVideo($event)"
                                    id="uploadFile" />
                                </span>
                              </div>
                            </div>
                            <div class="col-12">
                              <div v-if="videoPreview" id="preview" v-html="videoPreview"></div>
                            </div>
                          </div>
                        </template>

                        <template v-if="content_type_id == 6">
                          <div class="row">
                            <div class="col-12">
                              <div class="uploadOuter">
                                <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i>{{
                                  $t("general.upload") }}</label>
                                <strong>{{ $t("general.or") }}</strong>
                                <span class="dragBox">
                                  <i class="icon-feather-file-plus"></i>
                                  {{ $t("general.audio_file_drag_drop") }}
                                  <input accept="audio/*" type="file" @change="dragNdropAudio($event)"
                                    id="uploadFile" />
                                </span>
                              </div>
                            </div>
                            <div class="col-12">
                              <div v-if="audioPreview" id="preview" v-html="audioPreview"></div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="uk-card-header">
                    <div class="float-right">
                      <button @click="closeModal" class="
                          uk-button uk-button-outline-default
                          mr-2
                          small
                          closeButton
                        " type="button">
                        <i class="icon-arrow-left"></i> Geri Dön
                      </button>
                      <button @click="setData(content.id)" class="uk-button uk-button-primary small closeButton"
                        type="button">
                        <i class="icon-feather-save"></i> {{ $t("general.save") }}

                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";
import store from "@/core/services";
import moment from "moment";
import content_categories_module, {
  GET_FILE_TYPES,
  FILE_TYPES_URL,
  MODULE_NAME as CONTENT_CATEGORIES_MODULE,
  FILE_TYPES,
} from "@/core/services/store/content_categories.module";

import training_section_module, {
  BASE_URL as TRAINING_SECTION_BASE_URL,
  GET_ITEMS as GET_TRAINING_SECTIONS,
  MODULE_NAME as TRAINING_SECTION_MODULE,
  ERROR,
  ITEMS as TRAINING_SECTIONS,
  DELETE_ITEM as TRAINING_SECTION_DELETE,
} from "@/core/services/store/training_section.module";

import training_content_module, {
  BASE_URL as TRAINING_CONTENT_BASE_URL,
  DELETE_ITEM as TRAINING_CONTENT_DELETE,
  GET_ITEMS as GET_TRAINING_CONTENTS,
  MODULE_NAME as TRAINING_CONTENT_MODULE,
  ERROR as TRAINING_CONTENT_ERROR,
  ITEMS as TRAINING_CONTENTS,
} from "@/core/services/store/training_content.module";

import Swal from "sweetalert2";
const _CONTENT_CATEGORIES_MODULE = CONTENT_CATEGORIES_MODULE;
const _TRAINING_SECTION_MODULE = TRAINING_SECTION_MODULE;
const _TRAINING_CONTENT_MODULE = TRAINING_CONTENT_MODULE;

export default {
  name: "CatalogContentEdit",
  components: { draggable },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(_CONTENT_CATEGORIES_MODULE, content_categories_module);
    registerStoreModule(_TRAINING_SECTION_MODULE, training_section_module);
    registerStoreModule(_TRAINING_CONTENT_MODULE, training_content_module);
  },
  data() {
    return {
      form: {
        contents: [],
      },
      preview: null,
      is_demo: false,
      content_type_id: null,
      data: {},
      imageArea: false,
      urlArea: false,
      videoArea: false,
      audioArea: false,
      videoPreview: null,
      audioPreview: null,
      NewSectionIds: [],
    };
  },
  computed: {
    content_types: {
      get() {
        return store.getters[_CONTENT_CATEGORIES_MODULE + "/" + FILE_TYPES];
      },
      set(value) { },
    },
    training_sections: {
      get() {
        return store.getters[
          _TRAINING_SECTION_MODULE + "/" + TRAINING_SECTIONS
        ];
      },
      set(value) { },
    },
    formattedTrainingSection() {
      let results = [];
      if (
        this.training_sections === null ||
        !("data" in this.training_sections)
      )
        return [];
      this.training_sections.data.forEach((item) => {
        let educations = [];

        item.training_content.forEach((education) => {
          educations.push({
            name: education.name,
            training_content_type_id: education.training_content_type_id,
            training_file_type_id: education.training_file_type_id,
            id: education.id,
            score: education.score,
            duration: education.duration,
            training_content: education.training_content,
            content_link: education.content_link,
            is_demo: education.is_demo,
          });
        });

        results.push({
          name: item.name,
          id: item.id,
          educations: educations,
          show_order: item.show_order,
        });
      });
      results = results.slice().sort(function (a, b) {
        return a.show_order - b.show_order;
      });

      this.form.contents = results;
      return results;
    },
    formattedSection: {
      get() {
        return this.form.contents;
      },
      set(value) {
        this.form.contents = value;
      },
    },
    formattedContentTypes: {
      get() {
        let results = [];

        if (this.content_types === null) return [];
        this.content_types.forEach((item) => {
          if (item) {
            results.push({
              icon: item.icon,
              name: item.name,
              id: item.id,
              key: item.key_name,
            });
          }
        });
        return results;
      },
      set(value) { },
    },
  },
  methods: {
    createContentGroup() {
      Swal.fire({
        title: "İçerik Adı",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Oluştur",
        confirmButtonColor: "#ae71ff",
        cancelButtonText: "Vazgeç",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.length != 0) {
            let length = this.form.contents.length;
            this.NewSectionIds.push(length + 1);
            this.form.contents.push({
              id: length + 1,
              name: result.value,
              educations: [],
              status: "new",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Hata...",
              text: "Lütfen bir değer giriniz!",
              confirmButtonColor: "#ae71ff",
              confirmButtonText: "Tamam",
            });
          }
        }
      });
      this.setParent();
    },
    closeModal() {
      $(".uk-modal-close-default").click();
    },
    selectPickerTrigger() {
      setTimeout(function () {
        $(".selectpickerFileCategory").selectpicker();
      }, 300);
    },
    dragNdropModal(event, file_type) {
      let file = event.target.files[0];
      let accepted_files;

      if (file_type == 2) {
        //image
        accepted_files = [
          "image/gif",
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
        ];
      }
      if (file_type == 3) {
        //pdf
        accepted_files = ["application/pdf"];
      }
      if (file_type == 4) {
        //word
        accepted_files = ["application/msword"];
      }
      if (file_type == 5) {
        //power point
        accepted_files = [
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];
      }
      if (this.compareTypeCompatibality(file, this.getContentTypeById(this.content_type_id).accept)) {
        this.data.media = file;

        this.preview =
          '<div class="file-info">' +
          "<div>Format: <span>" +
          file.type +
          "</span></div>" +
          "<div>Doküman Adı: <span>" +
          file.name +
          "</span></div>" +
          "<div>Boyut: <span>" +
          this.bytesToSize(file.size) +
          "</span></div>" +
          "</div><br>" +
          '<div><img src="' +
          URL.createObjectURL(file) +
          '" /></div>';
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata...",
          text: "Lütfen geçerli bir döküman seçiniz.",
          confirmButtonColor: "#ae71ff",
          confirmButtonText: "Tamam",
        });
      }
    },
    dragNdropVideo(event) {
      let self = this;
      let file = event.target.files[0];

      let accepted_files = [
        "video/mp4",
        "video/m4v",
        "video/avi",
        "video/mov",
        "video/mpg",
        "video/mpeg",
      ];

      if (accepted_files.includes(file.type)) {
        self.data.media = file;

        let html =
          '<div class="file-info">' +
          "<div>Format: <span>" +
          file.type +
          ' <i class="uil-video" style="font-size: 14px"></i></span></div>' +
          "<div>Video Dosyası Adı: <span>" +
          file.name +
          "</span></div>" +
          "<div>Boyut: <span>" +
          self.bytesToSize(file.size) +
          "</span></div>" +
          "</div>";

        self.videoPreview = html;
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata...",
          text: "Lütfen geçerli bir video seçiniz.",
          confirmButtonColor: "#ae71ff",
          confirmButtonText: "Tamam",
        });
      }
    },
    compareTypeCompatibality(file, acceptableType) {
      return (
        acceptableType.includes(file.type) ||
        acceptableType.includes(file.name.split(".").pop()) ||
        (acceptableType.split("/")[0] == file.type.split("/")[0] &&
          file.type.split("/")[0].toLowerCase() != "application")
      );
    },
    dragNdropAudio(event) {
      let self = this;
      let file = event.target.files[0];

      if (
        this.compareTypeCompatibality(
          file,
          this.getContentTypeById(this.content_type_id).accept
        )
      ) {
        self.data.media = file;

        let html =
          '<div class="file-info">' +
          "<div>Format: <span>" +
          file.type +
          ' <i class="uil-microphone" style="font-size: 14px"></i></span></div>' +
          "<div>Ses Dosyası Adı: <span>" +
          file.name +
          "</span></div>" +
          "<div>Boyut: <span>" +
          self.bytesToSize(file.size) +
          "</span></div>" +
          "</div>";

        self.audioPreview = html;
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata...",
          text: "Lütfen geçerli bir ses dosyası seçiniz.",
          confirmButtonColor: "#ae71ff",
          confirmButtonText: "Tamam",
        });
      }
    },

    getContentTypeById(id) {
      let data;
      this.content_types.forEach((item) => {
        if (item.id == id) {
          data = item;
        }
      });
      return data;
    },
    changeOption(id) {
      if (id) {
        let ContentType = this.getContentTypeById(id);

        this.imageArea = false;
        this.urlArea = false;
        this.videoArea = false;
        this.audioArea = false;
        this.preview = null;
        this.videoPreview = null;
        this.audioPreview = null;
        if (
          ContentType.key == "documents.visual_document" ||
          ContentType.key == "documents.pdf_document" ||
          ContentType.key == "documents.word_document" ||
          ContentType.key == "documents.powerpoint_document"
        ) {
          this.imageArea = true;
        }
        if (
          ContentType.key == "documents.video_url" ||
          ContentType.key == "documents.url" ||
          ContentType.key == "documents.anzera_education" ||
          ContentType.key == "documents.webinar" ||
          ContentType.key == "documents.classroom_education"
        ) {
          this.urlArea = true;
        }
        if (ContentType.key == "documents.video_document") {
          this.videoArea = true;
        }
        if (ContentType.key == "documents.audio_document") {
          this.audioArea = true;
        }
      }
    },
    setParent() {
      let self = this;
      this.$emit("getData", self.formattedSection);
    },
    editData(content_id, educationIndex) {
      let self = this;

      self.form.contents.forEach((item, index) => {
        if (item.id == content_id) {
          //self.form.contents = self.formattedSection;
          if (self.data.hasOwnProperty("media")) {
            self.form.contents[index].educations[educationIndex]["media"] =
              self.data.media;
          }
        }
      });

      self.data = {};
      self.setParent();
      self.closeModal();
    },
    setData(content_id) {
      let self = this;

      self.data.content_type_id = self.content_type_id;
      self.data.is_demo = self.is_demo == true ? 1 : 0;

      if (
        !self.content_type_id ||
        !self.data.hasOwnProperty("content_type_id") ||
        !self.data.hasOwnProperty("name") ||
        !self.data.hasOwnProperty("score") ||
        !self.data.hasOwnProperty("duration")
      ) {
        Swal.fire({
          icon: "error",
          title: "Hata...",
          text: "Lütfen tüm alanları eksiksiz doldurunuz.",
          confirmButtonColor: "#ae71ff",
          confirmButtonText: "Tamam",
        });
      } else {
        self.form.contents.forEach((item, index) => {
          if (item.id == content_id) {
            self.data.training_file_type_id = self.content_type_id;
            self.form.contents[index].educations.push(self.data);
          }
        });

        self.preview = null;
        self.audioPreview = null;
        self.videoPreview = null;

        self.data = {};
        self.setParent();
        self.closeModal();
      }
    },
    editEducation(id, training_file_type_id) {
      this.selectPickerTrigger();

      if (id) {
        let self = this;
        let ContentType = self.getContentTypeById(training_file_type_id);
        let key = ContentType.key_name;

        self.imageArea = false;
        self.urlArea = false;
        self.videoArea = false;
        self.audioArea = false;
        self.preview = null;
        self.videoPreview = null;
        self.audioPreview = null;

        if (
          key == "visual_document" ||
          key == "pdf_document" ||
          key == "word_document" ||
          key == "powerpoint_document" ||
          key == "scorm_package"
        ) {
          self.imageArea = true;
        }
        if (
          key == "video_url" ||
          key == "url" ||
          key == "webinar" ||
          key == "classroom_education"
        ) {
          self.urlArea = true;
        }
        if (key == "video_document") {
          self.videoArea = true;
        }
        if (key == "audio_document") {
          self.audioArea = true;
        }
      }
    },
    deleteEducation(content_index, education_index) {
      let self = this;

      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#e31748",
      }).then((result) => {
        if (result.isConfirmed) {
          if (
            self.form.contents[content_index].educations[
              education_index
            ].hasOwnProperty("id")
          ) {
            var training_content_id =
              self.form.contents[content_index].educations[education_index].id;
            store.dispatch(
              _TRAINING_CONTENT_MODULE + "/" + TRAINING_CONTENT_DELETE,
              {
                url: TRAINING_CONTENT_BASE_URL + "/" + training_content_id,
              }
            );
          }

          self.form.contents[content_index].educations.splice(
            education_index,
            1
          );
          this.setParent();
        }
      });
    },
    deleteGroupById(content_id) {
      let self = this;

      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        html: "Eğer ki grubu silerseniz içerisindeki eğitimler de silinir.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#e31748",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!self.NewSectionIds.includes(content_id)) {
            store.dispatch(
              _TRAINING_SECTION_MODULE + "/" + TRAINING_SECTION_DELETE,
              {
                url: TRAINING_SECTION_BASE_URL + "/" + content_id,
              }
            );
          }

          self.form.contents.forEach((item, index) => {
            if (item.id == content_id) {
              self.form.contents.splice(index, 1);
            }
          });
          this.setParent();
        }
      });
    },
    updateGroupTitle(content_id, contentIndex) {
      let self = this;

      let content;
      self.form.contents.forEach((item, index) => {
        if (item.id == content_id) {
          content = item;
        }
      });

      Swal.fire({
        title: "İçerik Adı",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        inputValue: content.name,
        showCancelButton: true,
        confirmButtonText: "Güncelle",
        confirmButtonColor: "#ae71ff",
        cancelButtonText: "Vazgeç",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.length != 0) {
            self.form.contents.forEach((item, index) => {
              if (item.id == content_id) {
                self.form.contents[index].name = result.value;
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Hata...",
              text: "Lütfen bir değer giriniz!",
              confirmButtonColor: "#ae71ff",
              confirmButtonText: "Tamam",
            });
          }
        }
      });
    },
  },
  mounted() {
    if (!this.CatalogTrainingVisible) {
      this.$router.push({ name: "company.dashboard" });
    }
    store.dispatch(_CONTENT_CATEGORIES_MODULE + "/" + GET_FILE_TYPES, {
      url: FILE_TYPES_URL,
    });

    store.dispatch(_TRAINING_SECTION_MODULE + "/" + GET_TRAINING_SECTIONS, {
      url: TRAINING_SECTION_BASE_URL + "?training_id=" + this.$route.params.id,
    });
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.no-padding {
  padding: 0;
}

.btn-danger {
  background-color: #ed5252 !important;
  border-color: #ed5252 !important;
  color: #fff !important;
}

.bootstrap-select.btn-group.disabled {
  background: #8080802e !important;
}

.contentName {
  font-size: 0.75rem;
  background: #3e416d;
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
}

.c-curriculum .uk-accordion-title::before {
  content: "";
}
</style>